import {
  useClipboard,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box as CBox,
} from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import _ from "lodash"

export const KYCAlertJS = ({ user }) => {
  if (user?.kyc_status == 1) return null

  return (
    <>
      <CBox width="100vw" marginBottom={4} marginTop="-16px">
        <Alert status="error" justifyContent="center">
          <AlertIcon />
          <AlertTitle>KYC Pending!</AlertTitle>
          <AlertDescription>
            Please upload your documents, for KYC verification. &nbsp;
            <Link to="/my-documents/upload">
              {" "}
              <b>
                <u>Click Here</u>
              </b>{" "}
            </Link>
          </AlertDescription>
        </Alert>
      </CBox>
    </>
  )
}

export const KYCAlertJSOld = ({ user }) => {
  const [stage, setStage] = useState(null)

  useEffect(() => {
    if (user?.documents?.length) {
      const approved = _.find(user?.documents, (_doc) => {
        return _doc?.status === "APPROVED" ? true : false
      })

      if (approved) {
        setStage("APPROVED")
        return
      }

      const pending = _.find(user?.documents, (_doc) => {
        return _doc?.status === "PENDING" ? true : false
      })

      if (pending) {
        setStage("PENDING")
        return
      }

      setStage("REJECTED")
    } else {
      setStage("NOT_UPLOADED")
    }

    return () => {}
  }, [user])

  useEffect(() => {
    if (
      stage === null ||
      stage === "APPROVED" ||
      window?.location?.href?.includes("/ib/") ||
      window?.location?.href?.includes("/admin/")
    ) {
    } else {
      setPaddingTop("mc-main", "74px")
      setPaddingTop("mc-sidebar", "70px")
    }

    return () => {
      setPaddingTop("mc-main", "24px")
      setPaddingTop("mc-sidebar", "20px")
    }
  }, [stage])

  const setPaddingTop = (_el, _val) => {
    document.getElementsByClassName(_el)[0].style.paddingTop = _val
  }

  const getMessage = (_status) => {
    switch (_status) {
      case "PENDING":
        return "Your uploaded KYC documents are under verification"

      case "REJECTED":
        return "Your uploaded KYC document has been rejected, please reupload again."

      default:
        return "Please get your KYC done"
    }
  }

  if (
    stage === null ||
    stage === "APPROVED" ||
    window?.location?.href?.includes("/ib/") ||
    window?.location?.href?.includes("/admin/")
  ) {
    return null
  }

  return (
    <>
      <CBox width="100vw" marginBottom={4} marginTop="-16px">
        <Alert status="error" justifyContent="center">
          <AlertIcon />
          <AlertTitle>KYC Pending!</AlertTitle>
          <AlertDescription>
            {getMessage(stage)}
            &nbsp;
            {stage != "PENDING" ? (
              <Link to="/my-documents/upload">
                {" "}
                <b>
                  <u>Click Here</u>
                </b>{" "}
              </Link>
            ) : null}
          </AlertDescription>
        </Alert>
      </CBox>
    </>
  )
}
