import React, { useContext, useState, useRef, useMemo, useEffect } from "react"
import {
  LanguageDropdown,
  WidgetDropdown,
  ProfileDropdown,
} from "../components/header"
import { Button, Section, Box, Input } from "../components/elements"
import { DrawerContext } from "../context/Drawer"
import { ThemeContext } from "../context/Themes"
import { Logo } from "../components"
import data from "../data/master/header.json"
import useSession from "../hooks/useSession"
import { isEmpty } from "lodash"
import { Link } from "react-router-dom"
import {
  useClipboard,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box as CBox,
} from "@chakra-ui/react"
import _ from "lodash"
import { CONFIG_KEYS, getConfig, setConfig } from "../services/config.service"
import { KYCAlertJS } from "./KYCAlert"
import { useQuery } from "react-query"
import { userDashboardService } from "../services/client"
import { STORAGE } from "../config/storage"

export default function Header() {
  const { drawer, toggleDrawer } = useContext(DrawerContext)
  const { theme, toggleTheme } = useContext(ThemeContext)
  const searchRef = useRef()
  const [scroll, setScroll] = useState("fixed")
  const [search, setSearch] = useState("")

  const { onCopy, value, setValue, hasCopied } = useClipboard("")

  const [user, isLoading, setUser] = useSession()
  const clientDashboardQuery = useQuery(["clientDashboard"], () =>
    userDashboardService()
  )
  const accounts =
    clientDashboardQuery?.data?.data?.data?._dash?.users?.data ?? []

  window.addEventListener("beforeunload", (event) => {
    const neverShownModal = getConfig(
      CONFIG_KEYS.NEVER_SHOW_AI_FORM_MODAL_SHOWN
    )

    if (!neverShownModal) {
      setConfig(CONFIG_KEYS.AI_FORM_MODAL_SHOWN, false)
    }
  })

  window.addEventListener("scroll", () => {
    if (window.pageYOffset > 0) setScroll("sticky")
    else setScroll("fixed")
  })

  document.addEventListener("mousedown", (event) => {
    if (!searchRef.current?.contains(event.target)) {
      setSearch("")
    }
  })

  const renderIBClientNavButton = () => {
    if (!isEmpty(user)) {
      if (window?.location?.href?.includes("/ib/"))
        return (
          <Link to="/dashboard">
            <Button className="mc-btn primary">Client Dashboard</Button>
          </Link>
        )

      if (!window?.location?.href?.includes("/admin/"))
        return (
          <Link to="/ib/dashboard">
            <Button className="mc-btn primary">IB Dashboard</Button>
          </Link>
        )
    }

    return <></>
  }

  const renderCopyLink = useMemo(() => {
    if (!window?.location?.href?.includes("/ib/") || !isEmpty(user?.ib_code)) {
      return <> </>
    }

    const link = `${
      window.location.href?.split("/ib/")[0]
    }/register?ib_code=IB${user?.ib_code}`

    setValue(link)

    return (
      <>
        <div>
          <div>My IB Link</div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="mc-header-search">
              <Input type="text" value={link} />
            </div>
            &nbsp;
            <Button
              icon={hasCopied ? "done" : "content_copy"}
              onClick={() => {
                onCopy()
                setTimeout(() => {
                  onCopy()
                }, 100)
              }}
            />
          </div>
        </div>
      </>
    )
  }, [user, hasCopied, isLoading])

  // useEffect(() => {
  //   const elementsArray = ["mc-sidebar", "mc-main"]

  //   _.forEach(elementsArray, (_el) => {
  //     try {
  //       const el = document.getElementsByClassName(_el)?.length
  //         ? document.getElementsByClassName(_el)[0]
  //         : null

  //       if (el && showAlert) {
  //         el.style.top = "125px"
  //       } else {
  //         el.style.top = "75px"
  //       }
  //     } catch (error) {}
  //   })

  //   return () => {
  //     _.forEach(elementsArray, (_el) => {
  //       try {
  //         const el = document.getElementsByClassName(_el)?.length
  //           ? document.getElementsByClassName(_el)[0]
  //           : null

  //         el.style.top = "75px"
  //       } catch (error) {}
  //     })
  //   }
  // }, [user && showAlert])

  const handleBackToAdmin = () => {
    try {
      const admin = JSON.parse(localStorage.getItem(STORAGE.ADMIN))

      if (admin?.token) {
        localStorage.removeItem(STORAGE.ADMIN)
        localStorage.setItem(STORAGE.USER, JSON.stringify(admin))

        window.location.href = "/admin/dashboard"
      }
    } catch (error) {
      console.log(error)
    }
  }

  const renderBackToAdminButton = () => {
    let admin = localStorage.getItem(STORAGE.ADMIN)

    try {
      admin = JSON.parse(admin)

      if (admin?.token && !window.location.href.includes("/admin"))
        return (
          <>
            <div
              style={{ position: "fixed", left: 16, bottom: 16, zIndex: 2050 }}
            >
              <Button
                onClick={() => handleBackToAdmin()}
                className="mc-btn primary"
              >
                BACK TO ADMIN
              </Button>
            </div>
          </>
        )
    } catch (error) {
      return ""
    }
  }

  return (
    <>
      <Section
        as="header"
        className={`mc-header ${scroll} test`}
        style={{
          flexWrap: "wrap",
          background: "white",
          // top: "0px",
          // paddingTop: "0px",
        }}
      >
        <KYCAlertJS user={user} />
        <Logo
          src={data?.logo.src}
          alt={data?.logo.alt}
          name={data?.logo.name}
          href={data?.logo.path}
        />
        <Box className="mc-header-group">
          <Box className="mc-header-left">
            {/* <Button
            icon={data?.search.icon}
            className="mc-header-icon search"
            onClick={() => setSearch("show")}
          /> */}
            <Button
              icon={drawer ? "menu_open" : "menu"}
              className="mc-header-icon toggle"
              onClick={toggleDrawer}
            />

            {renderCopyLink}
            {/* <Box className={`mc-header-search-group ${search}`}>
            <form className="mc-header-search" ref={searchRef}>
              <Button className="material-icons">{data?.search.icon}</Button>
             />
            </form>
          </Box> */}
          </Box>
          <Box className="mc-header-right">
            {renderIBClientNavButton()}

            {/* <Button
            icon={theme}
            title={data.theme.title}
            onClick={toggleTheme}
            className={`mc-header-icon ${data.theme.addClass}`}
          /> */}

            <WidgetDropdown
              icon={data.notify.icon}
              title={data.notify.title}
              badge={user?.notificationsCount ?? 0}
              addClass={data.notify.addClass}
              dropdown={data.notify.dropdown}
              notifications={user?.notifications ?? []}
            />
            <ProfileDropdown
              name={user?.name}
              image={data.profile.image}
              username={""}
              dropdown={data.profile.dropdown}
              accounts={accounts}
              clientId={user?.id ?? ""}
            />
          </Box>
        </Box>
      </Section>

      {renderBackToAdminButton()}
    </>
  )
}
