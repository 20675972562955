import React from "react"
import Label from "./Label"

export default function Input({
  type,
  placeholder,
  className,
  label,
  errorMessage,
  ...rest
}) {
  return (
    <FormWrapper label={label} errorMessage={errorMessage}>
      <input
        type={type || "text"}
        placeholder={placeholder}
        className={className}
        {...rest}
      />
    </FormWrapper>
  )
}

export const FormWrapper = (props) => {
  const { label, errorMessage, children } = props

  return (
    <>
      <div>
        {label && <Label className="mc-label-field-title mb-2">{label}</Label>}

        {children}
        {errorMessage ? (
          <div class="text-danger my-1"> {errorMessage}</div>
        ) : null}
      </div>
    </>
  )
}
